export const TOTAL_BLOGS = [
    {
        title: "Design to Code",
        subTitle: "Locofy.ai – Turn Figma to Code!",
        time: 10.5,
        description: "Learn how Locofy.ai transforms your Figma designs into clean, efficient code effortlessly.",
        writerName: "Ananya Patel",
        postedStatus: "Posted 5 min ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Design", "Design To Code"]
    },
    {
        title: "Responsive Web Design",
        subTitle: "Mastering the Art of Responsive UI",
        time: 8.2,
        description: "Explore the principles and techniques behind creating responsive web designs that adapt flawlessly to any screen size.",
        writerName: "Vikram Sharma",
        postedStatus: "Posted 15 min ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Design", "Code" , "Design To Code"]
    },
    {
        title: "The Power of Integrations",
        subTitle: "Streamline Your Workflow with Seamless Integrations",
        time: 20,
        description: "Discover how integrating your favorite tools can enhance productivity and streamline your workflow.",
        writerName: "Priya Singh",
        postedStatus: "Posted 30 min ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Integrations", "Design To Code"]
    },
    {
        title: "Founder's Journey: From Idea to Launch",
        subTitle: "Behind the Scenes of a Startup",
        time: 12.8,
        description: "Follow the journey of a startup founder as they navigate the challenges and triumphs of bringing their vision to life.",
        writerName: "Rahul Verma",
        postedStatus: "Posted 1 hour ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Founder's Diary"]
    },
    {
        title: "Color Theory Basics",
        subTitle: "Understanding the Psychology of Color",
        time: 5,
        description: "Delve into the fundamentals of color theory and its impact on user experience and branding.",
        writerName: "Neha Gupta",
        postedStatus: "Posted 1 hour ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Design", "Founder's Diary"]
    },
    {
        title: "Optimizing Your Codebase",
        subTitle: "Tips for Improving Code Performance",
        time: 15.3,
        description: "Learn best practices for optimizing your codebase to enhance performance and maintainability.",
        writerName: "Amit Patel",
        postedStatus: "Posted 2 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Code"]
    },
    {
        title: "Maximizing Efficiency with Automation",
        subTitle: "Automate Repetitive Tasks and Boost Productivity",
        time: 30,
        description: "Discover how automation can revolutionize your workflow by eliminating repetitive tasks and freeing up time for innovation.",
        writerName: "Deepak Sharma",
        postedStatus: "Posted 2 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Code", "Integrations"]
    },
    {
        title: "The Art of UI/UX Design",
        subTitle: "Creating Engaging User Experiences",
        time: 12.5,
        description: "Explore the principles of UI/UX design and learn how to create captivating user experiences that drive engagement and satisfaction.",
        writerName: "Anjali Singh",
        postedStatus: "Posted 3 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Design", "Integrations"]
    },
    {
        title: "Navigating the World of APIs",
        subTitle: "A Beginner's Guide to APIs",
        time: 7.9,
        description: "Gain a foundational understanding of APIs and how they enable communication between different software applications.",
        writerName: "Rajesh Kumar",
        postedStatus: "Posted 3 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Code", "Integrations"]
    },
    {
        title: "Balancing Design and Functionality",
        subTitle: "Striking the Perfect Balance in Product Development",
        time: 18,
        description: "Learn strategies for achieving harmony between design aesthetics and functional requirements in product development.",
        writerName: "Meera Jain",
        postedStatus: "Posted 4 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Design", "Code"]
    },
    {
        title: "Harnessing the Power of AI in Design",
        subTitle: "AI-Driven Tools for Designers",
        time: 25.7,
        description: "Discover how AI-powered design tools are revolutionizing the creative process and empowering designers to achieve more.",
        writerName: "Arjun Gupta",
        postedStatus: "Posted 4 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Design"]
    },
    {
        title: "Effective Code Documentation",
        subTitle: "Best Practices for Writing Comprehensive Code Documentation",
        time: 9.6,
        description: "Learn essential tips and techniques for documenting your code effectively to improve readability and maintainability.",
        writerName: "Sneha Patel",
        postedStatus: "Posted 5 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Code"]
    },
    {
        title: "Building Scalable Web Applications",
        subTitle: "Strategies for Scalability and Performance",
        time: 22.4,
        description: "Explore scalable architecture patterns and performance optimization techniques for building robust web applications.",
        writerName: "Rohit Sharma",
        postedStatus: "Posted 5 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Code"]
    },
    {
        title: "The Role of Typography in Design",
        subTitle: "Choosing the Right Typeface for Your Project",
        time: 6.8,
        description: "Discover how typography impacts design aesthetics and learn how to select the perfect typeface for your project.",
        writerName: "Kritika Verma",
        postedStatus: "Posted 6 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Design"]
    },
    {
        title: "Continuous Integration and Deployment",
        subTitle: "Automating the Build and Deployment Process",
        time: 17.2,
        description: "Learn how CI/CD pipelines streamline the software development lifecycle and enable rapid and reliable deployments.",
        writerName: "Manish Gupta",
        postedStatus: "Posted 6 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Code", "Integrations"]
    },
    {
        title: "Exploring Design Thinking",
        subTitle: "A Human-Centered Approach to Problem Solving",
        time: 13.9,
        description: "Discover the principles of design thinking and how it can drive innovation and solve complex problems.",
        writerName: "Shreya Singh",
        postedStatus: "Posted 7 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Design"]
    },
    {
        title: "Securing Your Web Applications",
        subTitle: "Best Practices for Web Application Security",
        time: 11.1,
        description: "Learn essential security measures to protect your web applications from common vulnerabilities and attacks.",
        writerName: "Arun Kumar",
        postedStatus: "Posted 7 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Code"]
    },
    {
        title: "The Evolution of Design Tools",
        subTitle: "From Sketching to Prototyping",
        time: 14.6,
        description: "Trace the evolution of design tools from traditional sketching techniques to modern prototyping software.",
        writerName: "Nikhil Sharma",
        postedStatus: "Posted 8 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Design"]
    },
    {
        title: "Mastering Version Control with Git",
        subTitle: "Essential Git Commands and Workflows",
        time: 16.8,
        description: "Gain proficiency in Git version control and learn essential commands and workflows for collaborative software development.",
        writerName: "Sachin Gupta",
        postedStatus: "Posted 8 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Code"]
    },
    {
        title: "Creating Seamless User Onboarding Experiences",
        subTitle: "Best Practices for User Onboarding",
        time: 9.3,
        description: "Explore strategies for designing user onboarding experiences that engage and guide users through your product seamlessly.",
        writerName: "Pooja Patel",
        postedStatus: "Posted 9 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Design"]
    },
    {
        title: "Scaling Your Startup: Lessons Learned",
        subTitle: "Insights from Startup Growth",
        time: 23.5,
        description: "Discover valuable lessons and insights from scaling a startup and navigating the challenges of rapid growth.",
        writerName: "Rajat Sharma",
        postedStatus: "Posted 9 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Founder's Diary"]
    },
    {
        title: "Building Interactive Web Applications with React",
        subTitle: "Harnessing the Power of React Components",
        time: 19.7,
        description: "Learn how to build dynamic and interactive web applications using React.js and its component-based architecture.",
        writerName: "Aakash Patel",
        postedStatus: "Posted 10 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Code"]
    },
    {
        title: "Crafting Compelling Visual Stories",
        subTitle: "The Art of Visual Storytelling",
        time: 11.9,
        description: "Explore techniques for crafting compelling visual stories that captivate audiences and convey powerful messages.",
        writerName: "Divya Singh",
        postedStatus: "Posted 10 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Design"]
    },
    {
        title: "Exploring Microservices Architecture",
        subTitle: "Designing Scalable and Resilient Systems",
        time: 26.4,
        description: "Dive into the world of microservices architecture and learn how to design scalable and resilient systems for modern applications.",
        writerName: "Vivek Kumar",
        postedStatus: "Posted 11 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Code"]
    },
    {
        title: "Enhancing Collaboration with Remote Teams",
        subTitle: "Strategies for Effective Remote Collaboration",
        time: 14.2,
        description: "Discover strategies and tools for fostering collaboration and communication within remote teams to maximize productivity.",
        writerName: "Anjali Sharma",
        postedStatus: "Posted 11 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Integrations"]
    },
    {
        title: "The Psychology of User Interaction",
        subTitle: "Understanding User Behavior",
        time: 7.6,
        description: "Explore the psychological principles behind user interaction and learn how to design interfaces that resonate with users.",
        writerName: "Ravi Kumar",
        postedStatus: "Posted 12 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Design"]
    },
    {
        title: "Optimizing Database Performance",
        subTitle: "Improving Speed and Efficiency",
        time: 18.9,
        description: "Learn strategies for optimizing database performance to ensure your applications run smoothly and efficiently.",
        writerName: "Manoj Patel",
        postedStatus: "Posted 12 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Code"]
    },
    {
        title: "The Future of Web Development",
        subTitle: "Trends and Technologies Shaping the Industry",
        time: 21.3,
        description: "Explore emerging trends and technologies that are shaping the future of web development and revolutionizing the digital landscape.",
        writerName: "Neha Sharma",
        postedStatus: "Posted 13 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Code"]
    },
    {
        title: "Creating Accessible Web Experiences",
        subTitle: "Designing for Inclusive User Experiences",
        time: 10.8,
        description: "Learn best practices for designing accessible web experiences that accommodate users with diverse needs and abilities.",
        writerName: "Aarti Singh",
        postedStatus: "Posted 13 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Design"]
    },
    {
        title: "Continuous Learning in Software Development",
        subTitle: "The Key to Professional Growth",
        time: 15.6,
        description: "Discover the importance of continuous learning in software development and how it fuels professional growth and innovation.",
        writerName: "Deepika Patel",
        postedStatus: "Posted 14 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Code"]
    },
    {
        title: "Crafting Memorable Brand Experiences",
        subTitle: "Strategies for Brand Building",
        time: 12.1,
        description: "Explore strategies for crafting memorable brand experiences that resonate with your audience and drive brand loyalty.",
        writerName: "Amit Kumar",
        postedStatus: "Posted 14 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Design"]
    },
    {
        title: "Scalable Data Processing with Apache Spark",
        subTitle: "Harnessing the Power of Big Data",
        time: 28.5,
        description: "Learn how Apache Spark enables scalable and efficient data processing for big data analytics and machine learning applications.",
        writerName: "Suresh Gupta",
        postedStatus: "Posted 15 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Code"]
    },
    {
        title: "Human-Centered Design Principles",
        subTitle: "Putting Users First in Design",
        time: 9.7,
        description: "Explore human-centered design principles and learn how to create products and experiences that prioritize the needs of users.",
        writerName: "Rohini Patel",
        postedStatus: "Posted 15 hours ago",
        imgUrl: "assets/img/blog.png",
        avatarUrl: "assets/img/avatar.png",
        category: ["Design"]
    }
];

