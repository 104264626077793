import React from "react";
import "../styles/subscribe.css";

const Subscribe = () => {
  return (
    <>
      <div className="subsc">
        <div className="icon-subsc">
          <img src="/assets/img/subscribe.png" alt="" />{" "}
        </div>
        <div className="parent-subscribe">
          <p className="susbscribe">Keep your read mode on!</p>
          <p className="react-subs">
            Stay updated on the latest Locofy announcements and product updates
          </p>
        </div>

        <div className="field-input">
          <input
            type="text"
            className="cribe"
            placeholder="Enter email address"
            autocomplete="no"
          />
          <div className="sub-button">
            <button className="subs-button">Subscribe</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
