import React from 'react'

function SvgAboutus() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="179" height="179" viewBox="0 0 179 179" fill="none">
<g id="Group 1000005840">
<path id="Ellipse 1" d="M119.838 119.622C112.039 146.708 76.5729 160.275 49.486 152.476C22.3991 144.676 6.76359 116.395 14.5631 89.3081C22.3626 62.2212 39.091 40.6177 66.1779 48.4172C93.2648 56.2168 127.638 92.5346 119.838 119.622Z" fill="#DA4040"/>
<g id="Mask Group">
<mask id="mask0_83_1135" maskUnits="userSpaceOnUse" x="12" y="35" width="148" height="120">
<path id="Ellipse 4" d="M112.653 117.553C104.854 144.64 76.5729 160.275 49.486 152.476C22.3991 144.676 6.76359 116.395 14.5631 89.3081C22.3626 62.2212 125.42 28.5475 152.507 36.347C179.594 44.1465 120.453 90.4658 112.653 117.553Z" fill="#FFCB3C"/>
</mask>
<g mask="url(#mask0_83_1135)">
<g id="Group">
<g id="Group_2">
<g id="Group_3">
<path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M63.2097 78.8346C60.5638 76.8022 57.2399 75.6232 53.1848 75.6299C39.338 75.6526 31.2647 83.6855 27.1818 94.7868C21.4464 110.391 23.9121 132.237 28.4545 144.01C28.9857 145.386 30.5842 146.095 32.0232 145.599C33.4604 145.099 34.199 143.579 33.6678 142.203C29.456 131.287 27.1068 111.033 32.4268 96.5638C35.7149 87.6138 42.0631 80.946 53.2244 80.9262C55.6383 80.9224 57.64 81.5702 59.2818 82.6857C58.3498 83.6533 57.4436 84.6308 56.5669 85.6165C53.0612 89.5651 46.0779 101.29 44.4974 110.895C43.2481 118.495 45.3132 124.765 52.8833 126.651C57.2172 127.732 61.1322 126.136 64.252 122.607C68.0986 118.254 70.7113 110.69 71.258 102.895C71.715 96.4146 70.7489 89.8069 68.1132 84.8137C67.7442 84.1139 67.3432 83.4438 66.912 82.8066C85.7669 65.1357 114.245 51.7786 139.955 49.848C141.482 49.7337 142.616 48.4584 142.489 46.9992C142.361 45.5427 141.015 44.454 139.488 44.5683C112.672 46.5809 82.9686 60.4445 63.2097 78.8346ZM62.9214 86.7614C62.2029 87.5182 61.4996 88.282 60.8178 89.0517C57.6705 92.5955 51.4055 103.12 49.9862 111.742C49.2359 116.305 49.7208 120.394 54.2645 121.528C56.4933 122.084 58.393 120.996 59.9954 119.181C62.1217 116.776 63.7068 113.155 64.6963 109.108C66.2272 102.828 66.2929 95.5085 64.2712 89.7599C63.8978 88.698 63.4504 87.6901 62.9214 86.7614Z" fill="#0F0F0F"/>
</g>
<g id="Group_4">
<path id="Vector_2" fillRule="evenodd" clipRule="evenodd" d="M112.576 77.1725C118.117 66.1895 129.759 56.0952 140.862 49.9412C142.182 49.2089 142.626 47.59 141.852 46.3244C141.077 45.0613 139.375 44.6283 138.055 45.3606C126.057 52.0126 113.559 62.9895 107.574 74.8568C106.912 76.1704 107.495 77.7547 108.876 78.3931C110.257 79.0315 111.914 78.4861 112.576 77.1725Z" fill="#0F0F0F"/>
</g>
</g>
<g id="Group_5">
<path id="Vector_3" fillRule="evenodd" clipRule="evenodd" d="M138.391 44.974C128.093 47.324 114.308 44.1637 105.125 38.9551C103.79 38.2 102.076 38.6159 101.298 39.8812C100.519 41.1465 100.967 42.7868 102.3 43.5444C112.627 49.3965 128.156 52.8128 139.736 50.1705C141.233 49.83 142.148 48.3866 141.776 46.9532C141.404 45.5199 139.888 44.6335 138.391 44.974Z" fill="#0F0F0F"/>
</g>
</g>
</g>
</g>
<g id="Group_6">
<path id="Vector_4" fillRule="evenodd" clipRule="evenodd" d="M164.939 39.7665C165.067 39.2436 165.221 38.7429 165.387 38.2787C165.896 36.8857 167.287 37.2785 167.395 37.3107C167.62 37.3788 168.613 37.7209 168.526 38.8937C168.522 38.9638 168.416 39.4511 168.372 39.6379C168.276 40.036 168.171 40.4244 167.999 40.7955C167.856 41.4661 167.768 42.1731 167.796 42.8687C167.831 43.8942 168.084 44.9021 168.87 45.6204C170.273 46.9098 172.454 47.481 174.206 48.1976C174.937 48.4967 175.337 49.2558 175.148 49.9852C174.953 50.7136 174.23 51.1946 173.438 51.116C171.051 50.881 167.862 52.1062 165.734 53.2156C165.08 53.5564 164.165 54.5475 163.307 55.1061C162.616 55.5528 161.926 55.7891 161.338 55.7953C161.108 55.7974 159.871 55.6937 159.687 54.4005C159.638 54.0382 159.629 52.2096 159.64 52.0044C159.69 50.825 159.585 49.7302 159.068 48.6369C158.618 47.6889 157.996 46.7968 157.121 46.1544C156.768 45.8955 156.389 45.6678 156.011 45.4367C155.871 45.3799 155.479 45.2176 155.348 45.131C155.082 44.9564 154.908 44.7506 154.797 44.5626C154.585 44.2064 154.532 43.8373 154.618 43.4574C154.67 43.2057 154.793 42.924 155.075 42.6522C155.282 42.4612 155.7 42.2028 156.334 42.1365C156.996 42.067 158.336 42.1989 158.58 42.2041C160.278 42.2417 162.006 42.0614 163.533 41.3393C164.294 40.9775 164.603 40.4449 164.939 39.7665ZM164.715 44.1977C164.968 45.5608 165.577 46.8183 166.652 47.8033C167.094 48.2114 167.602 48.569 168.144 48.888C166.695 49.3486 165.301 49.9429 164.158 50.5355C163.788 50.7288 163.342 51.0842 162.862 51.4773C162.857 50.0692 162.626 48.7416 161.995 47.4095C161.621 46.6235 161.158 45.8714 160.594 45.1949C162.015 45.0721 163.417 44.7686 164.715 44.1977Z" fill="#0F0F0F"/>
</g>
</g>
</svg>
  )
}

export default SvgAboutus