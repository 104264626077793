import React from 'react'

function SvgArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="130" height="49" viewBox="0 0 130 49" fill="none">
  <path d="M0.936291 7.60683C0.637288 8.00262 0.715747 8.56586 1.11153 8.86486L7.56128 13.7374C7.95707 14.0364 8.52031 13.958 8.81931 13.5622C9.11832 13.1664 9.03986 12.6031 8.64407 12.3041L2.91096 7.97298L7.24211 2.23987C7.54112 1.84408 7.46266 1.28084 7.06687 0.981838C6.67108 0.682834 6.10784 0.761294 5.80884 1.15708L0.936291 7.60683ZM129.279 43.2277C129.654 42.9028 129.694 42.3355 129.369 41.9607L124.074 35.8533C123.749 35.4785 123.182 35.4381 122.807 35.7631C122.432 36.088 122.392 36.6552 122.717 37.03L127.424 42.4589L121.995 47.1658C121.62 47.4907 121.58 48.058 121.905 48.4328C122.23 48.8075 122.797 48.8479 123.172 48.523L129.279 43.2277ZM1.77685 9.03778C22.1046 6.20612 32.7067 13.1522 48.8928 21.7503C65.1191 30.3696 86.67 40.4566 128.627 43.445L128.755 41.6532C87.1037 38.6866 65.796 28.6952 49.7355 20.1639C33.6348 11.6112 22.5219 4.33435 1.52901 7.25866L1.77685 9.03778Z" fill="#D8D8D8"/>
</svg>
  )
}

export default SvgArrow