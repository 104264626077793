import React from 'react'

function SvgDevelopement() {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="39" height="35" viewBox="0 0 39 35" fill="none">
  <g clipPath="url(#clip0_83_801)">
    <path d="M34.3791 5.172H32.1198V7.12851H34.3791V5.172Z" fill="#0F0F0F"/>
    <path d="M29.86 5.172H27.6007V7.12851H29.86V5.172Z" fill="#0F0F0F"/>
    <path d="M25.3419 5.172H23.0826V7.12851H25.3419V5.172Z" fill="#0F0F0F"/>
    <path d="M38.8962 0.427734V34.4374H0.36797V0.427734H38.8962ZM2.32448 2.38424V9.76616H36.9397V2.38424H2.32448ZM36.9397 32.4056V11.7227H2.32448V32.4056H36.9397Z" fill="#0F0F0F"/>
    <path d="M24.777 15.2044L30.086 21.951L24.777 28.6977L23.3793 27.3017L27.5895 21.951L23.3793 16.6003L24.777 15.2044Z" fill="#0F0F0F"/>
    <path d="M15.9622 16.6003L11.752 21.951L15.9622 27.3017L14.4893 28.6977L9.18027 21.951L14.4893 15.2044L15.9622 16.6003Z" fill="#0F0F0F"/>
    <path d="M20.3847 15.1744H18.5035V28.7277H20.3847V15.1744Z" fill="#0F0F0F"/>
  </g>
  <defs>
    <clipPath id="clip0_83_801">
      <rect width="38.5282" height="34.0849" fill="white" transform="matrix(-1 0 0 1 38.8959 0.352478)"/>
    </clipPath>
  </defs>
</svg>)
}

export default SvgDevelopement