import React, { useState } from "react";
import "../styles/footer.scss";
import SvgFaceBook from "../utility/SvgFaceBook";
import SvgInsta from "../utility/SvgInsta";
import SvgLinkdin from "../utility/SvgLinkdin";
import SvgTwitter from "../utility/SvgTwitter";
import SvgYoutube from "../utility/SvgYoutube";
import Modal from '@mui/material/Modal';
import Privacy from "./Privacy";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TermsAndConditions from "./TermsAndConditions";
import CookiesPolicies from "./CookiesPolicies";
import { Margin } from "@mui/icons-material";
import { NavLink } from "react-router-dom";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '1100px',
  maxWidth : 'calc(100% - 200px)',
  maxHeight : 'calc(100% - 100px)',
  Margin:'-5px',
  overflowY :'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  fontFamily:'Poppins-light'
};
function handleClick() {
  window.scrollTo(0, 0);
}

const Footer = () => {
  const [activeModal, setActiveModal] = useState(0)

  const links = [
    { path: "#home", label: "Home" },
    { path: "#aboutus", label: "About Us" },
    { path: "#services", label: "Services" },
    { path: "#portfolio", label: "Portfolio" },
    { path: "#contactus", label: "Contact Us" },
  ];
  return (
    <>
      <div className="footer">
        <div className="heading">
          <span>Social</span>
          <span style={{ color: "#DA4040" }}>Teaser</span>
        </div>
        <div className="nav">
          {links.map((link, index) => (
            <a key={index} href={link.path}>
              {link.label}
            </a>
          ))}
        </div>

        <div className="social-media">
          <a href={"/"}>
            <SvgInsta/>
          </a>

          <a href={"/"}>
            <SvgFaceBook/>
          </a>
          <a href={"/"}>
            <SvgLinkdin/>
          </a>

          <a href={"/"}>
            <SvgTwitter/>
          </a>

          <a href={"/"}>
            <SvgYoutube/>
          </a>
        </div> 
 
        <div className="last">
            <span className="left">Copyright 2024</span>
            <span className="right">
            <NavLink to='/blogs'  onClick={handleClick}>
                Blogs
              </NavLink>
            <a onClick={()=>setActiveModal(3)} >Cookies Policy</a>
            <a onClick={()=>setActiveModal(2)} >Terms & Conditions</a>
            <a onClick={()=>setActiveModal(1)}>Privacy</a>
            </span>
        </div>
      </div>

      <Modal
        open={activeModal!==0}
        onClose={()=>setActiveModal(0)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         {activeModal===1?(<Privacy/>):(activeModal===2?(<TermsAndConditions/>):activeModal===3?(<CookiesPolicies/>):'')}
        </Box>
      </Modal>
    </>
  );
};

export default Footer;
