import React from 'react'

function CookiesPolicies() {
  return (
    <>
     <h1 > Cookie Policy</h1>
     <p>A cookie is a tiny piece of data (like a text file) that a website asks your browser to save on your device when you visit it. This helps the website remember stuff about you, like your language preference or login details. Cookies set by the website you're on are called first-party cookies. We also use third-party cookies, which come from a different website, for our advertising and marketing. Here's what we use cookies for:</p>
     
     <h4>Strictly Necessary Cookies</h4>
     <p>These cookies are crucial for our website to function correctly. They can't be turned off in our systems. They're typically set when you do something on the site that requires a service, like setting your privacy preferences, logging in, or filling out forms. You can set your browser to block or alert you about these cookies, but some parts of the site might not work properly if you do. These cookies don't keep any personal information that identifies you.
Please be aware that our Insights pages need certain cookies to work, and these will be treated as strictly necessary cookies when you read Insights because you've specifically asked for this service.</p>
   
   <h4>Targeting Cookies</h4>
   <p>Our advertising partners might use these cookies on our website. They're used to create a profile of your interests and show you ads on other sites that are relevant to you. They don't store your personal info directly, but they identify your browser and internet device uniquely. If you don't allow these cookies, you'll see less targeted ads.</p>
   
   <h4>Functional Cookies</h4>
   <p>These cookies help our website offer better features and personalization. These cookies could be set either by us or by third-party providers whose services we've included on our pages. If you don't allow these cookies, some or all of these services might not work as well.</p>
  
   <h4>Performance Cookies</h4>

   <p>These cookies let us track visits and where our site's traffic comes from, so we can measure and enhance how well our site works. They help us see which pages are the most and least popular and how visitors navigate our site. All the info these cookies gather is put together and doesn't identify individuals. If you don't allow these cookies, we won't know when you've visited our site, and we won't be able to keep an eye on how well it's doing</p>
   
    </>
   
  )
}

export default CookiesPolicies