import React from 'react'

function TermsAndConditions() {
  return (
    <>
    <h1>Terms & Conditions</h1>
    <h5> Important Notice </h5>
    <p>When you visit SOCIALTEASER’s website (www.socialteaser.com) or download materials from our site, or use any of our services, you agree to follow the terms and conditions outlined below. If you don't agree to these terms and conditions, please don't use SOCIALTEASER’s website or our services.</p>
    
    <h4>Use of the Site and Our Services</h4>
    
    <p>Whenever we mention "we," "us," or "our" on this website (referred to as 'the Site'), we're talking about SOCIALTEASER, the company that runs the Site.</p>
    <p>We might change these terms and conditions now and then without giving prior notice. After any changes, your use of our website will be governed by the updated version of these terms. It's a good idea to check the terms and conditions regularly on the website. Remember, when you use SOCIALTEASER’s services, you're also bound by the agreement you've agreed to as a user. Plus, using the website is subject to the Privacy Policy posted on the website.</p>
   
   <h4>General Terms</h4>
   <p>SocialTeaser is an IT services company registered and operating in India. All project work, including design, development, SEO, and SEM marketing activities, will be carried out at our development centers in India. Our regular working hours are from 09 AM to 7 PM, IST, Monday through Friday. We take Sundays off, as well as alternate Saturdays (Second & Fourth). Additionally, all public holidays observed in India or in the state of Chandigarh will also be considered holidays for our company.</p>
   
   <h4>Use of the Site</h4>
   <p>You won't be charged by us for using the Site, but you're responsible for paying for the internet connection you use to access it.
We might suspend, limit, or stop access to the Site at any time. We can't guarantee that the Site will be free of errors, and we're not responsible if it's unavailable.
The info on the website pages is for general use and might change without notice.
SOCIALTEASER and any third parties don't guarantee the accuracy, timeliness, performance, completeness, or suitability of the info and materials on the website for any specific purpose.
SOCIALTEASER isn't responsible for any inaccuracies, errors, defects, or faults related to the info and materials on the website.
Using any info or materials on the website is at your own risk, and SOCIALTEASER isn't responsible.
It's your job to make sure that any product, service, or info you get from the website meets your needs.


If you use the website without permission, you might have to pay damages or face legal consequences.
</p>

<h4>Links to Other Websites</h4>
<p>SOCIALTEASER doesn't vouch for any other websites you might access through our site or that are linked to our site. We're not responsible for how other websites handle privacy. Make sure to read the privacy policies of any other websites you visit. If you have any concerns about an external link, reach out to the website's administrator.</p>

<h4>Intellectual Property</h4>
<p>All the rights to the design, text, graphics, and other content on the Site are licensed to SocialTeaser. The content on this website is protected by international copyright laws and other intellectual property rights. All the company names and logos mentioned on this website are the trademarks, service marks, or trading names of their respective owners, including us. You're not allowed to change, copy, reproduce, republish, upload, post, transmit, or distribute any material or info from the website without our written permission, except where we explicitly say you can.</p>
<p>SocialTeaser respects the intellectual property of others. If you think that your work has been copied in a way that breaks copyright laws, please email us at <span>info@socialteaser.com</span></p>
  
  <h4>Advertising & Sponsorship</h4>
  <p>Our site might have ads and sponsorships. Advertisers and sponsors need to make sure that any material they submit to be included on the site follows international and national laws. We're not responsible for any mistakes or inaccuracies in advertising material.</p>

<h4>Confidentiality</h4>
<p>We know how important confidentiality is for your business to succeed. That's why we won't share any confidential info about the business or affairs of our customers, potential customers, or suppliers, except when required by law or a legal authority. We'll do our best to keep any confidential info from being published or shared.</p>

<h4>Applicability of Materials</h4>
<p>We've tried to make sure our website follows international laws, but we can't guarantee that the materials on our site are suitable or available for use in all countries. If you visit our site from somewhere else, it's your responsibility to follow all the laws that apply to you. If you use our site in a way that breaks any laws in your area, you're not allowed to use our site, and you should leave right away.</p>

<h4>Access to Password Protected Areas</h4>
<p>Only authorized users can access specific areas of our website that require a password. These areas are governed by a separate legal agreement between SocialTeaser and the user. Unauthorized access will be taken seriously.</p>

<h4>User Information</h4>
<p>We welcome feedback on how to make our website better. You can email feedback to info@socialteaser.com. Any feedback we receive will be considered non-confidential, and SocialTeaser has the right to use it however we see fit. Users agree not to send us any material that's defamatory, offensive, threatening, obscene, harassing, infringing, or otherwise illegal. We're not responsible for any such material, and we reserve the right to remove it from the website without any liability.</p>

<h4>Disabling Devices</h4>
<p>If users want to post content on the website, they shouldn't upload anything that contains viruses or other harmful devices. We do our best to keep our website free from viruses or harmful features, but we can't guarantee it, and we're not liable for any loss or damage caused to users.</p>

<h4>Governing Law</h4>
<p>Using this website and any disputes that arise from its use are subject to the laws of the State of Chandigarh in India. Any legal action or proceedings related to these Terms and Conditions will take place in the courts or tribunals in Chandigarh, India.</p>

</>
   
  )
}

export default TermsAndConditions