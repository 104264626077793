import React, { useState } from "react";
import "../styles/contactus.scss";
import { useForm } from "react-hook-form";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import SvgPattern from "../utility/SvgPattern";
function ContactUs() {
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const sendDetails = (data) => {
    setIsLoading(true);
    emailjs
      .send(
        "service_ofd17cj",
        "template_mo3ur8q",
        {
          name: data.fullName,
          email: data.email,
          pNumber: data.phoneNumber,
          company: data.company,
          message: data.message,
        },
        "TPo4QfCqucAl8gtR1"
      )
      .then((response) => {
        console.log("Email sent successfully:", response);
        toast.success(
          "We got you contact details, we will reach out to you soon!"
        );
        document.getElementById("contact-form").reset();
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
        toast.error("Failed to submit your contact details");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="contact-us-comp">
      <ToastContainer />
      <div className="head">
        <h5 className="head1">Contact Us</h5>
        <h2 className="head2">
          {" "}
          Get in <strong>Touch</strong> <SvgPattern />{" "}
        </h2>
      </div>
      <div className="parent">
        <div className="left">
          <h2>Contact Us</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur. Diam luctus lacinia
            fermentum tristique. Ultricies consectetur hendrerit mauris nec
            nulla. Viverra in malesuada facilisi sed condimentum rhoncus aenean
            mauris.
          </p>

          <div className="details">
          <div> <PhoneInTalkIcon/> +91 88718 88296 </div>
          <div> <EmailIcon/> info@socialteaser.com </div>
          <div> <LocationOnIcon/> Addrerss of the company </div>
          </div>
        </div>
        <form
          id="contact-form"
          className="right"
          onSubmit={handleSubmit(sendDetails)}
        >
          <div className="form-field w-50">
            <label htmlFor="fullName"> Name*</label>
            <input
              type="text"
              id="fullName"
              className="input"
              placeholder="Enter Here"
              {...register("fullName")}
            />
          </div>
          <div className="form-field w-50">
            <label htmlFor="phoneNumber">Phone Number*</label>
            <input
              type="text"
              id="phoneNumber"
              className="input"
              placeholder="Enter Here"
              {...register("phoneNumber")}
            />
          </div>
          <div className="form-field w-100">
            <label htmlFor="email">Email*</label>
            <input
              type="text"
              id="email"
              className="input"
              placeholder="Enter Here"
              {...register("email")}
            />
          </div>

          {/* <div className="form-field">
                <label htmlFor="company">Company</label>
                <input
                  type="text"
                  id="company"
                  className="input"
                  placeholder="Enter Here"
                  {...register("company")}
                />
              </div> */}

          <div className="form-field w-100">
            <label htmlFor="message">Comments</label>
            <textarea
              id="message"
              className="input"
              cols={5}
              placeholder="Enter Here"
              {...register("message")}
            />
          </div>

          <div className="btn">
            <button type="submit" className="ss" disabled={isLoading}>
              {isLoading ? "Sending..." : "Get Started"}
            </button>
          </div>
        </form>
      </div>

      <div className="head">
        <h5 className="head1">Career</h5>
        <h2 className="head2">
          {" "}<img src="/assets/img/mail.png"/>
          Work with <strong>Us</strong> 
        </h2>
        <p>If you are interested in working with us, please share your CV and portfolio on the following email address given below:</p>
        <h5 className="head1" style={{fontSize : '22px', fontWeight : 'bold', textTransform : 'lowercase'}}>info@socialteaser.com</h5>

      </div>
     
    </div>
  );
}

export default ContactUs;
