import React from 'react'
import { Segments } from '../constants/Service'
import Design from './Design'
import '../styles/service-comp.scss'

function ServiceComp() {
    const services = Segments
  return (
    <div className='service-comp'>
      <div className="seg1">
        <h5>Our Services</h5>
        <h1>
          Let's <strong> build </strong> something <span style={{ color: "#ee4455", fontWeight: 'bold' }}>big</span> together!
            </h1>
        <p>
        We are a full-service agency that can handle everything from marketing and design, to content and development, to help you launch and grow your brand. We have the talent, experience, and passion to create amazing products and services that stand out from the crowd.
        </p>
        <button>Book a Consultation</button>
 
      </div>
    <div className='seg2'>
    { services.map((segment, i)=>(
            <Design segment={segment} index= {i} isHome = {false}/ >
          ))}
    </div>
    <div className='seg3'>
<br/> <br/>
    </div>
    </div>
  )
}

export default ServiceComp
