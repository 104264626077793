import React from 'react'

function SvgPlanning() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="37" viewBox="0 0 44 37" fill="none">
  <path d="M33.1156 22.2462V2.47962C33.1156 1.21999 31.9092 0.198853 30.4211 0.198853H8.86548C8.62727 0.198924 8.39892 0.279036 8.23051 0.421584L1.04524 6.50368C0.876832 6.64623 0.782104 6.83959 0.782104 7.04115V34.4104C0.782104 35.67 1.98846 36.6911 3.47656 36.6911H30.4211C31.9092 36.6911 33.1156 35.67 33.1156 34.4104V32.8899H31.3193V34.4104C31.3193 34.8302 30.9172 35.1706 30.4211 35.1706H3.47656C2.98053 35.1706 2.57838 34.8302 2.57838 34.4104V7.3559L9.23731 1.71934H30.4211C30.9172 1.71934 31.3193 2.05974 31.3193 2.47962V22.2462H33.1156Z" fill="#0F0F0F"/>
  <path d="M7.96678 0.959351V5.52057C7.96678 5.94042 7.56464 6.2808 7.06861 6.2808H5.27234V7.80119H7.06861C8.5567 7.80119 9.76305 6.78011 9.76305 5.52057V0.959351H7.96678Z" fill="#0F0F0F"/>
  <path d="M25.9302 10.0821H7.96715C7.47112 10.0821 7.06897 10.4225 7.06897 10.8424V26.0477C7.06897 26.4676 7.47112 26.808 7.96715 26.808H25.9301C26.4262 26.808 26.8283 26.4676 26.8283 26.0477V10.8423C26.8283 10.4224 26.4263 10.0821 25.9302 10.0821ZM25.0321 25.2874H8.86533V11.6026H25.0321V25.2874Z" fill="#0F0F0F"/>
  <path d="M14.2525 10.8425H12.4562V21.4866H14.2525V10.8425Z" fill="#0F0F0F"/>
  <path d="M14.2524 23.767H12.4562V26.0471H14.2524V23.767Z" fill="#0F0F0F"/>
  <path d="M19.6433 16.9243H13.3562V18.4439H19.6433V16.9243Z" fill="#0F0F0F"/>
  <path d="M25.9303 16.9243H22.3376V18.4439H25.9303V16.9243Z" fill="#0F0F0F"/>
  <path d="M43.6297 20.013L41.1427 17.9078C40.792 17.611 40.2234 17.611 39.8727 17.9078L26.1929 29.4873C25.8423 29.7842 25.8423 30.2654 26.1929 30.5624L28.6799 32.6675C28.8483 32.8101 29.0767 32.8902 29.3148 32.8902C29.553 32.8902 29.7814 32.8101 29.9498 32.6675L43.6296 21.088C43.9803 20.7911 43.9803 20.3098 43.6297 20.013ZM29.3148 31.0549L28.0979 30.0248L40.5076 19.5203L41.7246 20.5505L29.3148 31.0549Z" fill="#0F0F0F"/>
  <path d="M27.7262 31.3688V29.8493H25.9299V32.1287C25.9299 32.5483 26.3321 32.8885 26.8281 32.8885H29.5226V31.3689H27.7262V31.3688Z" fill="#0F0F0F"/>
  <path d="M9.7627 32.1302H4.37378V33.6498H9.7627V32.1302Z" fill="#0F0F0F"/>
  <path d="M13.3553 32.1302H11.5591V33.6498H13.3553V32.1302Z" fill="#0F0F0F"/>
</svg>
  )
}

export default SvgPlanning