import React from "react";
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';import "../styles/animate2.scss";
import SvgPlanning from "../utility/SvgPlanning";
import SvgSketch from "../utility/SvgSketch";
import SvgDesign from "../utility/SvgDesign";
import SvgDevelopement from "../utility/SvgDevelopement";
import SvgDeliver from "../utility/SvgDeliver";
import SvgArrow from "../utility/SvgArrow";

function MovingAnimation2() {
  return (
    <div className="animation2">
      <div className="seg1">
        <h5>Workflow</h5>
        <h2>Our Process</h2>
        <p>
          We will bring the aura of our experience and industry knowledge to
          help your business succeed and grow.
        </p>
      </div>

      <div className="seg2">
        <div className="icon odd"> <Groups2OutlinedIcon/> <span className="title">Discuss</span> </div>
        <div className="wave odd"> <SvgArrow/> </div>

        <div className="icon even"> <SvgPlanning/> <span className="title">Planning</span> </div>
        <div className="wave even"> <SvgArrow/> </div>

        <div className="icon odd"> <SvgSketch/> <span className="title">Sketch</span> </div>
        <div className="wave odd"> <SvgArrow/> </div>

        <div className="icon even"> <SvgDesign/> <span className="title">Design</span> </div>
        <div className="wave even"> <SvgArrow/> </div>

        <div className="icon odd"> <SvgDevelopement/> <span className="title">Developement</span> </div>
        <div className="wave odd"> <SvgArrow/> </div>

        <div className="icon even"> <SvgDeliver/> <span className="title">Deliver</span> </div>
      </div>
     </div>
  );
}

export default MovingAnimation2;
