export const Segments = [
    {
      id: 1,
      title: "Design",
      description: "Amazing online experiences happen when we combine smart UX planning with top-notch design. At SocialTeaser we make awesome online experiences by carefully planning how users interact with a website or app and that’s what we excel at.",
      features: [
        "UI & UX Design", 
        "Website Design", 
        "Application Design", 
        "Landing Page Design", 
        "Design System", 
        "Packaging Design", 
        "Book Design", 
        "Design Revamp"
      ],
      imgSrc: "assets/img/img4.png"
    },
    {
      id: 2,
      title: "Development & Testing",
      description: "We have an excellent team of developers who can make awesome designs and build high-quality websites and application, no matter how complex. Whether you're big, medium or small, SocialTeaser is here for you.",
      features: [
        "Brand Strategy",
        "Logo Design",
        "Brand Guidelines",
        "Visual Identity",
        "Brand Messaging",
        "Market Research",
        "Brand Positioning",
        "Rebranding"
      ],
      imgSrc: "assets/img/img5.png"
    },
    {
      id: 3,
      title: "Mobile Marketing",
      description: "Mobile marketing lets businesses talk directly to people on their phones. It's quick, personal, and reaches many folks instantly. Since lots of people use smartphones, mobile marketing ensures your message stands out, boosting interactions and sale",
      features: [
        "Bulk SMS",
        "RCS (Rich Communication Services)",
        "Bulk Whatsapp",
        "Bulk Email",
        "OBD Calls",
        "IVR Calls",
        "Missed call alert",
        "Click to call",

      ],
      imgSrc: "assets/img/img6.png"
    },
    {
      id: 4,
      title: "Branding",
      description: "At SocialTeaser, we position ourselves as your creative partner and focusing dedicated to one goal, using our creative skills to showcase your product. We invest time into your business, finding key points to highlight, and crafting impressive visuals that make an impact \"wow\" factor around them.",
      features: [
        "Branding Kit",
        "Logo Design + Logo Kit",
        "Market Research",
        "Animations",
        "Iconographic",
        "Video Editing",
        "Motion Graphics",
        "Brand Strategy",

      ],
      imgSrc: "assets/img/img7.jpg"
    },
    {
      id: 5,
      title: "Digital Marketing",
      description: "We help our clients to generate leads, raise brand awareness, increase sales, and reach other business goals. Our smart team specializes in SEO, SMO, SEM, and SMM to improve your ranking on search engines like Google. We work as your partner, our SEO specialists handle keyword ranking, content optimization, and on-page and off-page SEO.",
      features: [
        "SEO (Search Engine Optimization)",
        "SMO (Social Media Optimization)",
        "SEM (Search Engine Marketing)",
        "SMM (Social Media Marketing",
        "Content Marketing",
        "PPC (Pay-Per-Click",
      ],
      imgSrc: "assets/img/img6.png"
    },
    {
      id: 6,
      title: "Business Consult",
      description: "At Social Teaser we analyse the company's challenges and opportunities, develop strategies, and implement solutions to enhance operations, efficiency, and profitability. Business consultants often specialize in areas such as strategy, management, finance, marketing, human resources, or technology.",
      features: [
        "Start-up Consulting",
        "Legal and Compliance Consulting",
        "Strategy Consulting",
        "Website Design Consulting",
        "Sales Consulting",
        "Business Process Re-engineering",
      ],
      imgSrc: "assets/img/img6.png"
    }
  ];
  
