import React from 'react'
import Blog from './blog'
import Portfolio from './Portfolio'
import Testimonial from './Testimonial'
import Form from './Form'

function Services() {
  return (
    <div>
        <Blog/>
    
        <Portfolio/>
        <Testimonial/>
        <Form/>
    </div>
  )
}

export default Services