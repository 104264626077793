import React, { useState } from "react";
import { TOTAL_BLOGS } from "../constants/blogList.js";
import "../styles/blog.scss";
import BlogHeader from "./BlogHeader.js";
import Subscribe from "./Subscribe.js";
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { Link } from "react-router-dom";

function Blog() {
  const blogs = TOTAL_BLOGS;
  const categories = [
    "All",
    "Design",
    "Design To Code",
    "Code",
    "Integrations",
    "Founder's Diary",
  ];
  const [selectedCat, setSelectedCat] = useState("All");

  return (
 <>
    <BlogHeader />
    <div className="blog-main">
      <h5 className="h5">Discover</h5>
      <h2 className="h2">Topics Of Your Choice</h2>

      <div className="categories">
        {categories.map((cat, index) => (
          <div
            className={`category ${selectedCat === cat ? "selected" : ""}`}
            onClick={() => setSelectedCat(cat)}
          >
            {" "}
            {cat}{" "}
          </div>
        ))}
      </div>
      <div className="blog-parent">
        {blogs
          .filter((b) => {
            if (selectedCat === "All") return true;
            else return b.category.includes(selectedCat);
          })
          .map((blog, index) => (
            <div key={index} className="blog-card">
              <div className="img1">
                {" "}
                <img src={blog.imgUrl} alt="Blog" />{" "}
              </div>
              <div className="blog-details">
                <div className="layer1">
                  <div className="h2">{blog.title}</div>
                  <div className="time"> {blog.time.toFixed(0)} min read</div>
                </div>
                <h3 className="layer2">
                  <div className="icon-c">
                   <Link to='/about-detail'> <NorthEastIcon/> </Link>
                    </div>
                  {blog.subTitle}
                  </h3>
                <p className="description">{blog.description}</p>
                <span className="hr"></span>
                <div className="author-info">
                  <div className="img2"><img src={blog.avatarUrl} alt="Author"  /></div>
                  <div className="author-desc">
                    <p className="name">{blog.writerName}</p>
                    <p className="status">{blog.postedStatus}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
    <Subscribe />
 </>
  );
}

export default Blog;
