import React from "react";
import "../styles/movingAnimation.scss";
import SvgAboutus from "../utility/SvgAboutus";
import SvgRoughUnderline from "../utility/SvgRoughUnderline";

function MovingAnimation() {
  return (
    <div className="aboutUsAnimat">
      <div className="seg1">
        <h5>About Us</h5>
        <h1>
          <strong>We </strong> help<strong> you </strong>
          <span style={{ color: "#ee4455" }}>reach</span> heights with{" "}
          <span style={{ position: "relative" }}>
            branding.
            <span style={{ position: "absolute", left: "0ch", top: "1.3ch" }}>
              <SvgRoughUnderline />
            </span>
          </span>
        </h1>
        <p>
          We are a full-service agency that can handle everything from marketing
          and design, to content and development, to help you launch and growth.
        </p>
        <button>Explore now</button>
        <div style={{ position: "absolute", right: "-16%", top: "45px" }}>
          <SvgAboutus />
        </div>
      </div>

      <div className="seg2">
        <div className="left">
          <p>
            Social Teaser, where we trade in innovation, run on creativity and
            thrive on game-changing strategies. Simply put, we are never ones to
            merely follow trends, especially at our agency. Driven by the focus
            on innovation and the obsession with quality, we are set on shifting
            the Benchmark within the Digital Sphere.
          </p>
          <p>
            Collaboration is not only a good thing at our agency, it is
            celebrated. The most innovative ideas come when we work together,
            which is why we create action where collaboration happens. We create
            through collaboration: from brainstorm sessions to project
            kick-offs, we make sure to gather voices and minds from a variety of
            perspectives and disciplines to make sure our solutions are greater
            than the parts that make them up.{" "}
          </p>
        </div>
        <hr />
        <div className="right">
          <h5>Our vision</h5>
          <h2>You Guessed it. We’re Changing the Game.</h2>
        </div>
      </div>

      <div className="anim-down">
        <span className="content">Marketing</span>
        <span className="dot"></span>
        <span className="content">Motion</span>
        <span className="dot"></span>
        <span className="content">Animation</span>
        <span className="dot"></span>
        <span className="content">UI/UX</span>
        <span className="dot"></span>
        <span className="content">creative design</span>
        <span className="dot"></span>
        <span className="content">Marketing</span>
        <span className="dot"></span>
        <span className="content">Motion</span>
        <span className="dot"></span>
        <span className="content">Animation</span>
        <span className="dot"></span>
        <span className="content">UI/UX</span>
        <span className="dot"></span>
        <span className="content">creative design</span>
        <span className="dot"></span>
      </div>

      <div className="anim-right">
        <span className="content">Marketing</span>
        <span className="dot"></span>
        <span className="content">Motion</span>
        <span className="dot"></span>
        <span className="content">Animation</span>
        <span className="dot"></span>
        <span className="content">UI/UX</span>
        <span className="dot"></span>
        <span className="content">creative design</span>
        <span className="dot"></span>
        <span className="content">Marketing</span>
        <span className="dot"></span>
        <span className="content">Motion</span>
        <span className="dot"></span>
      </div>
    </div>
  );
}

export default MovingAnimation;
