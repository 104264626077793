import React, { useState } from "react";
import Portfolio from "./Portfolio";
import MovingAnimation from "./MovingAnimation";
import MovingAnimation2 from "./MovingAnimation2";
import Testimonial from "./Testimonial";
import Form from "./Form";

import "../styles/aboutus.scss";

function AboutUs() {
  const [withUs, setWithUs] = useState(false);

  return (
    <div>
      <MovingAnimation />

      <div className="about-seg2">
        <div className="seg1">
          <h5>We make you grow</h5>
          <h2>We Reduce Time and Bla Bla</h2>
          <div className="tags" onClick={(e) => setWithUs(!withUs)}>
            <div className={`tag ${withUs === true ? "active" : ""}`}>
              With Us
            </div>
            <div className={`tag ${withUs === false ? "active" : ""}`}>
              Without Us
            </div>
            <div
              className={`active-bg ${withUs === false ? "active" : ""}`}
            ></div>
          </div>
          <p>
            We will bring the aura of our experience and industry knowledge to
            help your business succeed and grow.
          </p>
          {withUs === true ? (
            <ul className="list-with-svg-icon">
              <li>
                <strong>Nice Designs:</strong> Eye-catching websites and
                marketing for attention and conversion.
              </li>
              <li>
                <strong>User-friendly websites:</strong> Beautiful, efficient,
                secure—bridging you to the online world.
              </li>
              <li>
                <strong>Marketing:</strong> We boost your brand's reach and
                engagement through SEO and social media.
              </li>
              <li>
                <strong>Time-saving:</strong> We handle digital needs, letting
                you focus on business.
              </li>
              <li>
                <strong>Efficient Cost:</strong> Our services streamline
                processes, saving you money and budget.
              </li>
              <li>
                <strong>Ongoing Support:</strong> We're here to help and adjust
                as you grow.
              </li>
            </ul>
          ) : (
            <ul className="list-with-svg-icon">
              <li>
                <strong>Inconsistent Look:</strong> Struggling to achieve a
                unified brand appearance.
              </li>
              <li>
                <strong>Low Development:</strong> Creating a great website is
                tough without web design skills.
              </li>
              <li>
                <strong>Ineffective Marketing:</strong> Wastes time and money
                without targeted strategies in place.
              </li>
              <li>
                <strong>Time-consuming Tasks:</strong> Design and development
                tasks take time; doing it alone drains productivity.
              </li>
              <li>
                <strong>Higher Costs:</strong> Lack of streamlined processes and
                expertise impacts your budget.
              </li>
              <li>
                <strong>Poor Growth:</strong> Lack of ongoing support and
                mentorship may occur.
              </li>
            </ul>
          )}
        </div>

        <div className="seg2">
          <img src="/assets/img/portfolio6.png" alt="" />
        </div>
      </div>

      <div className="about-seg3">
        <div className="left">
          <h5>Our Mission</h5>
          <h2>You Guessed it. We’re Changing the Game.</h2>
        </div>
        <hr />
        <div className="right">
          <p>
            Social Teaser, where we trade in innovation, run on creativity and
            thrive on game-changing strategies. Simply put, we are never ones to
            merely follow trends, especially at our agency. Driven by the focus
            on innovation and the obsession with quality, we are set on shifting
            the Benchmark within the Digital Sphere.
          </p>
          <p>
            Collaboration is not only a good thing at our agency, it is
            celebrated. The most innovative ideas come when we work together,
            which is why we create action where collaboration happens. We create
            through collaboration: from brainstorm sessions to project
            kick-offs, we make sure to gather voices and minds from a variety of
            perspectives and disciplines to make sure our solutions are greater
            than the parts that make them up.{" "}
          </p>
        </div>
      </div>
      <MovingAnimation2 />
      <Portfolio />
      <Testimonial />
      <Form />
    </div>
  );
}

export default AboutUs;
