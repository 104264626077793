import React from "react";

function SvgPattern() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="51" viewBox="0 0 48 51" fill="none">
    <path d="M24.1541 31.2819C24.1541 31.2819 14.0562 21.8466 5.21094 20.2198" stroke="#DA4040" stroke-width="3" stroke-linecap="round"/>
    <path d="M33.9289 28.5553C33.9289 28.5553 35.755 6.88031 39.6035 2" stroke="#DA4040" stroke-width="3" stroke-linecap="round"/>
    <path d="M20.1123 43.3067C20.1123 43.3067 5.10139 41.3677 2.00159 43.4061" stroke="#DA4040" stroke-width="3" stroke-linecap="round"/>
  </svg>
  );
}

export default SvgPattern;
