import React from "react";
import "../styles/blogHeader.scss";
import Avatar from "@mui/material/Avatar";

const BlogHeader = () => {
  return (
    <>
      <div className="blg-1">
        <div className="parentblogh">
          <div className="designtocide">
            <p className="para">DESIGN TO CODE</p>
          </div>
    <p className="time-to-read">3 min to read</p>
          <div>
          </div>
          <div className="game">
            <span className="large">
              You Guessed it. We’re Changing the Game.
            </span>
            <p className="small">
              Lorem ipsum dolor sit amet consectetur. Nisi cursus dui eget proin
              suscipit mattis amet lectus nibh. Amet non in elit aenean. Semper
              in amet diam et vel. Erat nulla enim eget ac tristique.
            </p>
          </div>
          <hr className="single-line" />
          <div className="avatar">
            <Avatar
              sx={{ width: 60, height: 60 }}
              alt="Anna Rosé"
              src="assets/img/avatar.png"
            />
            <div className="avatarname">
              <div className="ava">Anna Rosé</div>
              <div className="status">Posted just now</div>
            </div>
          </div>
          <div className="tarbutoon">
            <button className="buttonava">Read More</button>
          </div>
        </div>
        <div className="image">
          <img src="assets/img/blog.png" />
        </div>
      </div>
    </>
  );
};

export default BlogHeader;
