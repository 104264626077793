// import React from 'react'
// import "../styles/design.scss";

// const Design = () => {
//   return (
//     <>
//     <div className='parent-design'>
//     <div>

//     </div>
//     </div>
//     </>
//   )
// }

// export default Design

import React from 'react';
import "../styles/design.scss";



const Design = ({segment, index, isHome}) => {
  const classes = `design-segment ${index%2==0?'even':'odd'} ${isHome===true?'home-seg':''} `
  return (
  
        <div key={segment.id} className={classes} >
          <div className="left">
            <img src={segment.imgSrc} alt={segment.title} />
          </div>
          <div className="right">
           <h5>Real-time Analytics</h5>
            <h2>{segment.title}</h2>
            <p>{segment.description}</p>
            <div className='list-with-svg-icon'>
              {segment.features.map((feature, index) => (
                <div className='item' key={index}>{feature}</div>
              ))}
            </div>
            <button className='btn'>Schedule a call </button>
          </div>
        </div>
      )
};

export default Design;

