export const WHY_US_DATA = [
    {
        title: 'Take on Any Challenge of the Digital World',
        description: "We will bring the aura of our experience and industry knowledge to help your business succeed and grow.",
        listItem1: 'We design websites that look amazing',
        listItem2: 'We develop websites that are quick',
        listItem3: 'We help you grow your social media',
        imgName: 'img4.png'
    },
    {
        title: 'Boost Your Online Presence',
        description: "Let us enhance your online presence through cutting-edge strategies and innovative solutions.",
        listItem1: 'Customized digital marketing campaigns',
        listItem2: 'SEO optimization for better visibility',
        listItem3: 'Engaging content creation',
        imgName: 'img5.png'
    },
    {
        title: 'Stay Ahead of the Curve',
        description: "Keep up with the rapidly evolving digital landscape with our proactive approach and forward-thinking solutions.",
        listItem1: 'Continuous technology upgrades',
        listItem2: 'Regular performance analysis and optimization',
        listItem3: 'Adaptation to emerging trends',
        imgName: 'img6.png'
    },
    {
        title: 'Deliver Exceptional User Experiences',
        description: "We prioritize user satisfaction by creating intuitive interfaces and seamless interactions.",
        listItem1: 'User-centric design principles',
        listItem2: 'Accessibility and usability testing',
        listItem3: 'Responsive and mobile-friendly layouts',
        imgName: 'img7.jpg'
    }
];
