import React from "react";

function privacy() {
  return (
    <>
    <div style={{'fontSize':'14px'}}>
      <h1>Privacy Policy</h1>
      <div>
        <p>
          THIS PRIVACY POLICY IS ALL ABOUT THE INFO WE GATHER FROM USING SOCIAL
          TEASER'S WEBSITES, LIKE SOCIALTEASER.COM, AS WELL AS ANY SECTIONS,
          PAGES, LIBRARIES, OR COMMUNICATION PLATFORMS LINKED TO THE SITE. IT
          ALSO COVERS ANY OTHER SERVICES WE OFFER. WE'RE SOCIAL TEASER, AND WHEN
          WE TALK ABOUT "OUR ENVIRONMENT," WE MEAN BOTH THE SERVICES AND THE
          SITE.
        </p>
        <p>
          MAKE SURE YOU READ THIS PRIVACY POLICY CAREFULLY. IF YOU DON'T AGREE
          WITH THE TERMS, THEN PLEASE DON'T USE OR VISIT ANY PART OF THE SITE,
          SOCIALTEASER’S SERVICES, OR OUR ENVIRONMENT. BY USING OR VISITING THE
          SITE, OUR SERVICES, OR OUR ENVIRONMENT, YOU'RE AGREEING TO THE TERMS
          LAID OUT IN THIS PRIVACY POLICY
        </p>
        <p>
          IF YOU USE OUR SERVICES, YOU AND SOCIALTEASER MIGHT DECIDE TO MAKE
          ANOTHER AGREEMENT THAT ADDS MORE TERMS AND CONDITIONS ABOUT PERSONAL
          INFORMATION AND PRIVACY. THIS IS CALLED AN "ADDITIONAL PRIVACY
          AGREEMENT." IF YOU AND SOCIALTEASER MAKE ONE OF THESE AGREEMENTS, THE
          TERMS OF THAT AGREEMENT ARE MORE IMPORTANT THAN WHAT'S WRITTEN IN THIS
          PRIVACY POLICY. IF THERE'S EVER A CONFLICT BETWEEN THE TERMS OF AN
          ADDITIONAL PRIVACY AGREEMENT AND WHAT'S IN THIS PRIVACY POLICY, THE
          TERMS OF THE ADDITIONAL PRIVACY AGREEMENT WIN
        </p>
      </div>
      <h3>Our Promise to Protect Your Privacy </h3>
      <p>
        At SocialTeaser, we value your privacy and handle all the information we
        receive, control, or process in line with this privacy policy. This
        policy guides how we use data we collect, including any personal
        identifiable information or personal health information. We never sell
        or rent Personal Data collected when you use our Site, our Services, or
        our Environment.
      </p>
      <h3>
        We made this glossary to help you grasp how we safeguard your privacy:
      </h3>
      <ul>
        <li>
          "Personal Data" refers to any information that can identify you as an
          individual.
        </li>
        <li>
          "Information Requests" cover data that doesn't identify individual
          users, like aggregated information about how users use our
          Environment.
        </li>
        <li>
          A "Cookie" is a tiny bit of data sent to your browser from a web
          server when you visit a website.
        </li>
        <li>
          An "Automated Decision" is when a decision is made automatically
          without any human input.
        </li>
      </ul>
      <h3>
        What kind of Personal Data might we gather? Here are some examples:
      </h3>
      <ul>
        <li>
          Identification and Contact Information: This includes details like
          your name, job title, business address, phone numbers, and email
          address.
        </li>
        <li>
          Business Details: Information about your business that we collect when
          you use our services or give us instructions.
        </li>
        <li>
          Marketing Information: Details about your preferences that are
          relevant to the services we offer.
        </li>
        <li>
          Digital Identification: This covers things like your IP address, what
          kind of device you're using, the type of browser and operating system,
          which pages you visit on our site, when you visited (including your
          time zone), and any other information sent in an HTTP request, such as
          how you got to our site.
        </li>
        <li>
          We might also collect other data not mentioned here while providing
          our services. Rest assured, this data gets the same protection and
          security outlined in this privacy policy.
        </li>
      </ul>
      <h2>Cookies</h2>
      <h3>In general, we use Cookies to:</h3>
      <ul>
        <li>
          {" "}
          Remember who you are and access information you've previously
          provided, so you don't have to fill it in again.
        </li>
        <li> Collect stats about how our site is used.</li>
        <li> Understand browsing habits.</li>
        <li>
          {" "}
          Offer relevant content, marketing, ads, and products based on your
          interests and location.
        </li>
        <li>
          {" "}
          Keep track of your progress and engagement with our site's content.
        </li>
      </ul>
      <p>
        Third parties who access Personal Data through a Cookie might use it to
        market or advertise to you. Sometimes, the third parties on our site
        might use their own cookies. You can check out our full Cookie policy
        here. If you want to delete any Cookies we've placed on your device to
        access our site, you can do so through your browser. Some of our
        Services rely directly on data gathered through Cookies. If you choose
        not to allow them, we might not be able to provide some parts of our
        Services to you.
      </p>
      <h4>How we’ll Use Your Personal Data</h4>
      <h4>
        When you use our Environment, you're agreeing to let SocialTeaser do any
        of the following:
      </h4>
      <ul>
        <li>Provide info, products, goods, and services you've asked for.</li>
        <li>Respond to information requests.</li>
        <li>
          Enhance our marketing efforts, analyse how our Environment is used,
          fix server issues, improve our content and products, and personalize
          our site's content, layout, and services.
        </li>
        <li>
          Manage and handle your organization's business relationship with us,
          like processing payments, accounting, auditing, billing, and support
          services.
        </li>
        <li>Meet our legal obligations, such as keeping records.</li>
        <li>
          Safeguard the security of our IT and communication systems, online
          platforms, websites, and other systems, and spot and prevent security
          threats, fraud, or other unlawful activities.
        </li>
        <li>
          Send you targeted info that matches your interests, like targeted ads,
          new service offers, and product promotions.
        </li>
        <li>
          Combine user info we collect with offline data from other sources to
          improve our services and carry out targeted advertising more
          effectively.
        </li>
      </ul>
      <p>
        If the information request comes from or through a third party, like our
        contractors or business partners, we'll share your Personal Data as
        needed for them to respond. These third parties aren't allowed to use
        your Personal Data beyond what's necessary to help us, and they must
        safeguard it and not share it with others. When we need consent for
        using your Personal Data according to laws, we'll ask for it. Sometimes,
        we might use your info as a data processor when one of our clients
        authorizes us to process data they have. In this case, our client must
        make sure they're allowed to share that info with us, and that we can
        collect, use, and share it as described in this policy. They also need
        to ensure the person whose data it is knows about this Privacy Policy or
        has given their consent to our client to share the info with us.
        SocialTeaser is a global company, so we might share info with our
        worldwide workforce, which might involve international transfers, as per
        applicable laws.
      </p>
      <h3>How We Keep Your Personal Data Safe</h3>
      <p>
        We store the Personal Data we collect in a secure and controlled
        environment, and we might keep it for as long as needed to protect our
        rights, as required by law. We work hard to keep your Personal Data
        safe. We have physical, technical, and procedural measures in place that
        are suitable for the sensitivity of the Personal Data we handle. These
        measures are meant to stop your Personal Data from being lost or
        accessed, collected, used, disclosed, copied, changed, thrown away, or
        destroyed without permission. It's important for you to protect your
        data too. Make sure no one gets unauthorized access to your computers,
        account, or password. Always remember to click "exit" when you finish
        browsing our Environment on a shared computer or public network
      </p>
      <h3>What Are My Rights?</h3>
      <p>
        If we need your consent to process your Personal Data, you can withdraw
        that consent anytime. If you gave consent to share your details with a
        third party and want to withdraw it, you should also contact that third
        party to update your preferences. If you want to stop getting direct
        marketing or newsletters that you previously signed up for, you can use
        the unsubscribe option in those messages or send an email request to
        privacy@socialteaser.com. This unsubscribe action is linked to an
        individual email address, not an account or person. If you use different
        email addresses with us (like personal and work emails), you need to
        unsubscribe each one to stop getting messages. We don't use any type of
        Automated Decision that affects you or your Personal Data. According to
        the privacy laws in your area, we provide specific rights to you under
        certain circumstances. If you want to make a request about your Personal
        Data that we collected or have questions about your rights, email your
        request to privacy@socialteaser.com. We'll update your Personal Data in
        our databases once we get the info we need to process your request. As a
        user of our Environment, you're responsible for making sure the data you
        give us is accurate and true. Remember, it's up to you to keep it
        updated if anything changes.
      </p>
      <h3>Can You Refuse to Share Your Personal Data with Us?</h3>
      <p>
        Usually, you give us your Personal Data voluntarily. If you choose not
        to share it or don't give consent for us to use it, there generally
        won't be any negative consequences for you. However, there might be
        cases where we can't provide our services or give you access to our
        Environment without getting certain data. For instance, we might need to
        do legal compliance checks or require data to process your instructions
        or orders, or to give you access to our services, site, or
        communications on the Environment. If we can't provide what you're
        asking for without the necessary Personal Data, we'll let you know.
      </p>
      <h3>Children Under 18</h3>
      <p>
        We don't have a way to tell the age of people who use our Environment,
        so we apply the same Privacy Policy to everyone. If a child gives us
        Personal Data without their parent's or guardian's permission, the
        parent or guardian should get in touch with us to delete that info and
        opt out of any promotions.
      </p>
      <h3>Third Party Practices</h3>
      <p>
        The privacy policy of an advertiser or promotional service you see in
        our Environment might be different from ours. We suggest you check out
        that third party's privacy policy before you respond to their offer. If
        third parties handle Personal Data for us, they have to follow the rules
        we've set out here and any relevant information security standards.
      </p>
      <h3>Links to Other Websites</h3>
      <p>
        Our site might have links to other websites, but this privacy policy
        doesn't cover those sites. Just because we link to them doesn't mean we
        endorse them or have checked their security or privacy practices. The
        rules we talk about here don't apply to those external sites. We can't
        control or be responsible for how they handle privacy or what's on their
        sites. We recommend checking out their privacy policies.
      </p>
      <h3>Changes to Our Policy</h3>
      <p>
        If we make any changes to our Privacy Policy, we'll let you know through
        our site at least twenty-four (24) hours before the changes take effect.
        Any info we collected before the changes will still be protected
        according to the old Privacy Policy. This privacy policy doesn't give
        anyone new rights or add to our responsibilities beyond what's required
        by privacy laws. If there's ever a conflict between this policy and the
        law, we'll follow the law. If you have any questions about this Privacy
        Policy, feel free to email us at privacy@socialteaser.com.
      </p>
      <p>This privacy policy was last updated on May 15, 2024</p>
    </div>

    </>
  )
}
export default privacy;
