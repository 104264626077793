
import React from 'react'
import { HashRouter, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Head from './components/Head';
import Home from './components/Home';
import Footer from './components/footer';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import BlogHeader from './components/BlogHeader';
import BlogDeatil from './components/BlogDeatil';
import ContactUs from './components/ContactUs';
import Design from './components/Design';
import { Segment } from './constants/Service';
import ServiceComp from './components/ServiceComp';
import './App.css'

const App = () => {
  return (
    <div>
        <Router>
          <div className='notification'> The site is under maintenance right now. But soon will be updated. </div>
          <Head/>
          <Routes>
            <Route  path='/' element={<Home/>}/>
            <Route  path='/about' element={<AboutUs/>}/>
            <Route path='/blogs' element={<Services  />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/service' element={<ServiceComp />} />
          </Routes>
          <Footer />
        </Router>
    </div>
  )
}

export default App

